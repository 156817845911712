import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const SupplierDetail = () => {
    const { id } = useParams();
    const selectedYear = localStorage.getItem('selectedYear') || '';
    const selectedCustomer = localStorage.getItem('selectedCustomer') || '';
    const [supplierData, setSupplierData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [brregData, setBrregData] = useState({
        organisasjonsnummer: '',
        navn: '',
        organisasjonsform: '',
        postadresse: {
            kommune: '',
            landkode: '',
            postnummer: '',
            adresse: [],
            land: '',
            poststed: ''
        },
        forretningsadresse: '',
        registrertIMvaregisteret: false,
        maalform: '',
        naeringskode1: '',
        naeringskode1_kode: '',
        naeringskode2: '',
        naeringskode2_kode: '',
        naeringskode3: '',
        naeringskode3_kode: '',
        hjelpeenhetskode: '',
        stiftelsesdato: '',
        antallAnsatte: '',
        overordnetEnhet: '',
        vedtektsdato: '',
        vedtektsfestetFormaal: [],
        aktivitet: [],
        hjemmeside: ''
    });

    const [activeTab, setActiveTab] = useState('supplierDetails'); // Default active tab

    useEffect(() => {
        const fetchSupplierData = async () => {
            try {
                const response = await fetch(`https://app.ecofocus.no:3443/api/supplierdata/data/${id}?year=${selectedYear}&customer=${selectedCustomer}`);
                const data = await response.json();
                setSupplierData(data);
            } catch (error) {
                console.error('Error fetching supplier data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSupplierData();
    }, [id, selectedYear, selectedCustomer]);

    const fetchBrregData = async () => {
        try {
            const orgNr = supplierData.RegistrationNumber || document.getElementById('orgNr').value;
            if (!orgNr) {
                console.error('No organization number provided');
                return;
            }

            const response = await fetch(`https://data.brreg.no/enhetsregisteret/api/enheter/${orgNr}`);
            const brregData = await response.json();

            if (!brregData || !brregData.navn || !brregData.organisasjonsform?.beskrivelse) {
                console.error('Brreg data is incomplete or missing');
                return;
            }

            setSupplierData((prevData) => ({
                ...prevData,
                Name: brregData.navn || prevData.Name,
                OrgType: brregData.organisasjonsform?.beskrivelse || prevData.OrgType,
                Postadresse: brregData.postadresse?.adresse.join(', ') || prevData.Postadresse,
                Kommune: brregData.postadresse?.kommune || prevData.Kommune,
                Postnummer: brregData.postadresse?.postnummer || prevData.Postnummer,
                Land: brregData.postadresse?.land || prevData.Land,
                Forretningsadresse: brregData.forretningsadresse?.adresse.join(', ') || prevData.Forretningsadresse,
                naeringskode1: brregData.naeringskode1?.beskrivelse || '',
                naeringskode1_kode: brregData.naeringskode1?.kode || '', // Set kode value
                naeringskode2: brregData.naeringskode2?.beskrivelse || '',
                naeringskode2_kode: brregData.naeringskode2?.kode || '', // Set kode value
                naeringskode3: brregData.naeringskode3?.beskrivelse || '',
                naeringskode3_kode: brregData.naeringskode3?.kode || '', // Set kode value
                hjelpeenhetskode: brregData.hjelpeenhetskode?.beskrivelse || '',
                stiftelsesdato: brregData.stiftelsesdato || '',
                antallAnsatte: brregData.harRegistrertAntallAnsatte ? brregData.antallAnsatte : '',
                overordnetEnhet: brregData.overordnetEnhet || '',
                vedtektsdato: brregData.vedtektsdato || '',
                vedtektsfestetFormaal: brregData.vedtektsfestetFormaal?.join(', ') || '',
                aktivitet: brregData.aktivitet?.join(', ') || '',
                hjemmeside: brregData.hjemmeside || ''
            }));
        } catch (error) {
            console.error('Error fetching Brreg data:', error);
        }
    };

    const handleSaveChanges = async () => {
        try {
            const response = await fetch(`https://app.ecofocus.no:3443/api/supplierdata/data/${supplierData.id}?year=${selectedYear}&customer=${selectedCustomer}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(supplierData),
            });

            if (!response.ok) {
                throw new Error(`Failed to save changes: ${response.statusText}`);
            }

            const result = await response.json();
            console.log("Changes saved", result);
        } catch (error) {
            console.error("Error saving changes:", error);
        }
    };

    if (loading) {
        return <p>Loading supplier data...</p>;
    }

    if (!supplierData) {
        return <p>No supplier data found.</p>;
    }
    // Tab content for supplier details
const SupplierDetailsContent = () => (
  <div>
      <h1>Detaljer</h1>
      <p><strong>ID:</strong> {supplierData.id}</p>
      <p><strong>Type:</strong> {supplierData.Type}</p>
      <p><strong>Org.Nr:</strong>
          <input value={supplierData.RegistrationNumber}
                 onChange={(e) => setSupplierData({ ...supplierData, RegistrationNumber: e.target.value })} />
      </p>
      <p><strong>LeverandørID:</strong>
          <input value={supplierData.CustomerSupplierID}
                 onChange={(e) => setSupplierData({ ...supplierData, CustomerSupplierID: e.target.value })} />
      </p>
      <p><strong>Customer Factor:</strong>
          <input value={supplierData.CustomerFactor}
                 onChange={(e) => setSupplierData({ ...supplierData, CustomerFactor: e.target.value })} />
      </p>
      <p><strong>Factor Year:</strong>
          <input value={supplierData.CustomerFactorYear}
                 onChange={(e) => setSupplierData({ ...supplierData, CustomerFactorYear: e.target.value })} />
      </p>
      <h2>Opplysninger</h2>
      <button onClick={fetchBrregData}>Hent fra Brreg</button>

      {/* Conditional rendering of naeringskode fields */}
      {supplierData.naeringskode1 && (
          <p>
              <strong>Næringskode 1:</strong> {supplierData.naeringskode1} ({supplierData.naeringskode1_kode})
          </p>
      )}
      {supplierData.naeringskode2 && (
          <p>
              <strong>Næringskode 2:</strong> {supplierData.naeringskode2} ({supplierData.naeringskode2_kode})
          </p>
      )}
      {supplierData.naeringskode3 && (
          <p>
              <strong>Næringskode 3:</strong> {supplierData.naeringskode3} ({supplierData.naeringskode3_kode})
          </p>
      )}

      {supplierData.hjelpeenhetskode && <p><strong>Hjelpeenhetskode:</strong> {supplierData.hjelpeenhetskode}</p>}
      {supplierData.stiftelsesdato && <p><strong>Stiftelsesdato:</strong> {supplierData.stiftelsesdato}</p>}
      {supplierData.antallAnsatte && <p><strong>Antall Ansatte:</strong> {supplierData.antallAnsatte}</p>}
      {supplierData.overordnetEnhet && <p><strong>Overordnet Enhet:</strong> {supplierData.overordnetEnhet}</p>}

      <p><strong>Vedtektsdato:</strong> {supplierData.vedtektsdato}</p>
      <p><strong>Vedtektsfestet Formål:</strong> {supplierData.vedtektsfestetFormaal}</p>
      <p><strong>Aktivitet:</strong> {supplierData.aktivitet}</p>

      <p><strong>Postadresse:</strong> {supplierData.Postadresse}</p>
      <p><strong>Kommune:</strong> {supplierData.Kommune}</p>
      <p><strong>Postnummer:</strong> {supplierData.Postnummer}</p>
      <p><strong>Land:</strong> {supplierData.Land}</p>
      <p><strong>Forretningsadresse:</strong> {supplierData.Forretningsadresse}</p>
      <p><strong>Målform:</strong> {supplierData.maalform}</p>
      <p><strong>Hjemmeside:</strong> {supplierData.hjemmeside}</p>

      <button onClick={handleSaveChanges}>Lagre endringer</button>
  </div>
    );


    const SupplierTransactionsContent = () => {
      const correctid = supplierData.CustomerSupplierID;
      const [transactions, setTransactions] = useState([]);
      const [loading, setLoading] = useState(true);
      const [error, setError] = useState(null);
      const [selectedTransactions, setSelectedTransactions] = useState({});

      const [editableValues, setEditableValues] = useState({
          debitAmount: '',
          debitAmountWithFactor: '',
          averageFactor: '',
          category: '',
          classification: '',
          scope: '',
          calculationDisplay: ''
      });

      useEffect(() => {
          const fetchTransactions = async () => {
              try {
                  const response = await fetch(`https://app.ecofocus.no:3443/api/suppliertransactions/${correctid}?year=${selectedYear}&customer=${selectedCustomer}`);
                  if (!response.ok) {
                      throw new Error('Failed to fetch transactions');
                  }
                  const data = await response.json();
                  setTransactions(data.transactions);
              } catch (error) {
                  setError(error.message);
                  console.error('Error fetching transactions:', error);
              } finally {
                  setLoading(false);
              }
          };

          fetchTransactions();
      }, [correctid, selectedYear, selectedCustomer]);

      const handleCheckboxChange = (transactionID) => {
          setSelectedTransactions((prevSelected) => ({
              ...prevSelected,
              [transactionID]: !prevSelected[transactionID],
          }));
      };

      const parseNumber = (value) => {
          return parseFloat(value.toString().replace(',', '.'));
      };

      const calculateTotals = () => {
          const totals = transactions.reduce((totals, transaction) => {
              if (selectedTransactions[transaction.ID]) {
                  const debitAmount = parseNumber(transaction.DebitAmount);
                  const debitAmountWithFactor = parseNumber(transaction.DebitAmountWithFactor);
                  console.log("debitAmountWithFactor",debitAmountWithFactor);
                  const factor = parseNumber(transaction.Factor);
                  const calculation = transaction.Calculation;

                  totals.debitAmount += isNaN(debitAmount) ? 0 : debitAmount;
                  totals.debitAmountWithFactor += isNaN(debitAmountWithFactor) ? 0 : debitAmountWithFactor;


                  totals.factorSum += isNaN(factor) ? 0 : factor;
                  totals.selectedCount++;
                  totals.category = transaction.Category || totals.category;
                  totals.classification = transaction.Classification || totals.classification;


                  if (!totals.calculations.includes(calculation)) {
                      totals.calculations.push(calculation);
                  }

                  if (totals.scope === null) {
                      totals.scope = transaction.Scope;
                  } else if (totals.scope !== transaction.Scope) {
                      totals.scope = 'DIFF';
                  }
              }
              return totals;
          }, { debitAmount: 0, debitAmountWithFactor: 0, factorSum: 0, selectedCount: 0, category: "", classification: "", scope: null, calculations: [] });

          totals.averageFactor = totals.selectedCount > 0 ? (totals.factorSum / totals.selectedCount).toFixed(8) : '0.00000000';

          totals.calculationDisplay = totals.calculations.length === 1 ? totals.calculations[0] : 'DIFF';
          totals.totaldeltpaantall = totals.selectedCount;
          console.log("selcount", totals.selectedCount);
          return totals;
      };

      const handleInputChange = (e) => {
          const { name, value } = e.target;
          setEditableValues((prevValues) => ({
              ...prevValues,
              [name]: value
          }));

          // Calculate average factor when debitAmountWithFactor changes
          if (name === 'debitAmountWithFactor') {
              const newDebitAmountWithFactor = parseNumber(value);
              const newAverageFactor = editableValues.debitAmount > 0 ? newDebitAmountWithFactor / editableValues.debitAmount : 0;
              setEditableValues((prevValues) => ({
                  ...prevValues,
                  averageFactor: newAverageFactor.toFixed(8) // Keep eight decimal places
              }));
          }
      };



      const handleSave = async () => {
          try {

              const response = await fetch(`https://app.ecofocus.no:3443/api/suppliertransactions/saveSupplierTransactions/${correctid}?year=${selectedYear}&customer=${selectedCustomer}`, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                      editableValues,
                      selectedTransactions: Object.keys(selectedTransactions).filter(transactionID => selectedTransactions[transactionID]),
                      averageFactor: editableValues.averageFactor

                  }),
              });
              if (!response.ok) {
                  throw new Error('Failed to save data');
              }
              alert('Data saved successfully');
          } catch (error) {
              console.error('Error saving data:', error);
          }
      };

      const selectedCount = Object.values(selectedTransactions).filter(Boolean).length;
      const { debitAmount, debitAmountWithFactor, factorSum, averageFactor, category, classification, scope, calculationDisplay, totaldeltpaantall } = calculateTotals();

              console.log(totaldeltpaantall);
      useEffect(() => {
          setEditableValues({
              debitAmount: debitAmount || '',
              debitAmountWithFactor: debitAmountWithFactor || '',
              averageFactor: averageFactor,
              category: category || '',
              classification: classification || '',
              scope: scope || '',
              calculationDisplay: calculationDisplay || '',
              totaldeltpaantall: totaldeltpaantall || ''
          });
      }, [debitAmount, debitAmountWithFactor, averageFactor, category, classification, scope, calculationDisplay, totaldeltpaantall]);
console.log("totaldeltpaantall", totaldeltpaantall);
      if (loading) {
          return <p>Loading transactions...</p>;
      }

      if (error) {
          return <p>Error: {error}</p>;
      }

      return (
          <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                  <h2>Transactions</h2>
                  <p>Selected Transactions: {selectedCount}</p>
                  <table>
                      <thead>
                          <tr>
                              <th>Select</th>
                              <th>Transaction ID</th>
                              <th>Account ID</th>
                              <th>Transaction Date</th>
                              <th>Debit Amount</th>
                              <th>Category</th>
                              <th>Classification</th>
                              <th>Factor</th>
                              <th>Kundefaktor</th>
                              <th>Debit Amount With Factor</th>
                              <th>Scope</th>
                              <th>Kalkyle</th>
                          </tr>
                      </thead>
                      <tbody>
                          {transactions.map((transaction) => (
                              <tr key={transaction.ID}>
                                  <td>
                                      <input
                                          type="checkbox"
                                          checked={!!selectedTransactions[transaction.ID]}
                                          onChange={() => handleCheckboxChange(transaction.ID)}
                                      />
                                  </td>
                                  <td>{transaction.TransactionID}</td>
                                  <td>{transaction.AccountID}</td>
                                  <td>{new Date(transaction.TransactionDate).toLocaleDateString()}</td>
                                  <td>{transaction.DebitAmount}</td>
                                  <td>{transaction.Category}</td>
                                  <td>{transaction.Classification}</td>
                                  <td>{transaction.Factor}</td>
                                  <td>{transaction.CustomerFactor}</td>
                                  <td>{transaction.DebitAmountWithFactor}</td>
                                  <td>{transaction.Scope}</td>
                                  <td>{transaction.Calculation}</td>
                              </tr>
                          ))}
                      </tbody>
                  </table>
              </div>

              {selectedCount > 0 && (
                  <div style={{
                      width: '300px',
                      position: 'fixed',
                      right: '0',
                      top: '10vh',
                      height: '80vh',
                      backgroundColor: '#f0f0f0',
                      padding: '20px',
                      boxShadow: '-2px 0 5px rgba(0,0,0,0.1)',
                      overflowY: 'auto',
                  }}>
                      <h3>Selected Transactions</h3>
                      <p>Count: {selectedCount}</p>
                      <div>
                          <label>Total Debit Amount</label>
                          <input type="text" name="debitAmount" value={editableValues.debitAmount} readOnly />
                      </div>
                      <div>
                          <label>Total Debit Amount With Factor</label>
                          <input type="text" name="debitAmountWithFactor" value={editableValues.debitAmountWithFactor} onChange={handleInputChange} />
                      </div>
                      <div>
                          <label>Average Factor</label>
                          <input type="text" name="averageFactor" value={editableValues.averageFactor} readOnly />
                      </div>
                      <div>
                          <label>Category</label>
                          <input type="text" name="category" value={editableValues.category} onChange={handleInputChange} />
                      </div>
                      <div>
                          <label>Classification</label>
                          <input type="text" name="classification" value={editableValues.classification} onChange={handleInputChange} />
                      </div>
                      <div>
                          <label>Scope</label>
                          <input type="text" name="scope" value={editableValues.scope} onChange={handleInputChange} />
                      </div>
                      <div>
                          <label>Kalkyle</label>
                          <input type="text" name="calculationDisplay" value={editableValues.calculationDisplay} onChange={handleInputChange} />
                      </div>
                      <button onClick={handleSave}>Save Changes</button>
                  </div>
              )}
          </div>
      );
  };



const SupplierSustainabilityContent = () => (

    <div>
        <h2>Bærekraft hos leverandør</h2>
        {/* Render Brreg data fields here */}
        {/* Example: */}
        <p>Her skal vi søke etter org.nr for å finne total omsetning for valgt leverandør? har brønnøysundregistrene en api som viser dette?</p>
        <p>Hvilket år kommer omsetningstallet over fra?</p>
        <p>Næringskode: {supplierData.naeringskode1_kode}</p>
        <p>Overordnet Næringskode: {supplierData.naeringskode1_kode} (skal være kun de to første tallene)</p>
        <p>Overordnet næringskode hentes fra https://www.ssb.no/klass/klassifikasjoner/6/koder via API </p>
        <p>Bruk denne https://data.ssb.no/api/v0/no/table/13932/ for å finne utslipp i fra den nærmeste overordnede næringskoden, her kan man ikke søke etter kode og må finne etter navn. (F.eks. "Faglig, vitenskapelig og teknisk tjenesteyting" med overordnet næringskode 69 til 75 må gå under "Tjenesteytende næringer ellers"). Vi må finne for det året med data som er nærmes eller likt året vi bruker for å finne omsetning.</p>
        <p>Antall ansatte: {supplierData.antallAnsatte}</p>
        <p>Her finner vi total omsetning (Må omregnes fra millioner til kr) i næringskoden (to siffer) for år valgt over. https://data.ssb.no/api/v0/no/table/12910/</p>
        <p>Her finner vi antall sysselsatte i bransjen for riktig år: https://data.ssb.no/api/v0/no/table/12910/ </p>

        {/* More fields */}
    </div>
    );
        return (
          <div>
                    <h1>{supplierData.Name}</h1>
                    <div>
                        <button onClick={() => setActiveTab('supplierDetails')}>Informasjon</button>
                        <button onClick={() => setActiveTab('supplierTransactions')}>Transaksjoner</button>
                        <button onClick={() => setActiveTab('SupplierSustainability')}>Klimaregnskap</button>
                    </div>
                    <div>
                        {activeTab === 'supplierDetails' && <SupplierDetailsContent />}
                        {activeTab === 'supplierTransactions' && <SupplierTransactionsContent />}
                        {activeTab === 'SupplierSustainability' && <SupplierSustainabilityContent />}
                    </div>
                </div>
        );
};

export default SupplierDetail;
